import React, { useEffect, useState } from 'react';
import { Chat } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';

import './Chat.css';
import 'stream-chat-react/dist/css/index.css';

import loader from '../assets/ajax-loader.gif'

import { useChecklist } from '../components/Chat/ChecklistTasks';
import { CustomerApp } from '../components/Chat/CustomerApp';

import { Hook, Console, Decode } from 'console-feed'

const baseURL = window.location.protocol + '//' + window.location.host;
const urlParams = new URLSearchParams(window.location.search);

const apiKey = urlParams.get('key') || '';
//const targetOrigin = urlParams.get('target_origin') || '';

const theme = urlParams.get('theme') || 'light';
const customerChannelId = urlParams.get('channel') || '';
const customerUserId = urlParams.get('user') || '';
const customerUserToken = urlParams.get('token') || '';
const customerName = urlParams.get('name') || '';
const customerLastName = urlParams.get('lastname') || '';
const customerEmail = urlParams.get('email') || '';

const customerClient = StreamChat.getInstance(apiKey);


function App() {
  const [targetParent, setTargetParent] = useState('');
  const [client, setClient] = useState(null)
  const [loading, setLoading] = useState(true);
  // const [logs, setLogs] = useState([]);
  useEffect(() => {
    setTimeout(function () {
      // execute script
      const baseURL = window.location.protocol + '//' + window.location.host;
      setTargetParent(baseURL);
    }, 1000);

    if (customerUserId) {
      customerClient.connectUser(
        {
          id: customerUserId,
          name: `${customerName} ${customerLastName}`,
          email: customerEmail,
        },
        customerUserToken,
      ).then((c) => {
        setClient(c);
        setLoading(false);
      });

    }

    // Hook(window.console, log => {
    //     setLogs([...logs, Decode(log)])
    //     // console.log(`Base URL: ${baseURL}`)
    // })
  }, [])

  // useEffect(() => {
  //     console.log(targetParent)
  // }, [targetParent])

  // useChecklist(customerClient, targetOrigin);
  useChecklist(customerClient, targetParent);

  if (loading) {
    return <div className={"centeredView"}>
      <img src={loader} className={"centeredViewImage"} alt="Loading ..." />
    </div>
  }

  return (
    <>
      {customerClient && client && (
        <Chat client={customerClient} theme={`messaging ${theme}`}>
          <CustomerApp {...{ customerChannelId }} />
        </Chat>
      )}
      {/*<div style={{ backgroundColor: '#242424',height: 600 }}>*/}
      {/*    <Console logs={logs} variant="dark" />*/}
      {/*</div>*/}
    </>
  );
}

export default App;
