import React, { useEffect, useState } from 'react';
import {
  Channel,
  MessageInputFlat,
  MessageInput,
  MessageList,
  useChatContext,
  Window,
  Thread,
} from 'stream-chat-react';

import { EmptyStateIndicator } from '../CustomerEmptyStateIndicator/EmptyStateIndicator';
import { CustomerMessageInput } from '../MessageInput/CustomerMessageInput';

export const CustomerApp = ({ customerChannelId }) => {
  const { client: customerClient } = useChatContext();

  const [customerChannel, setCustomerChannel] = useState();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const getCustomerChannel = async () => {
      const newChannel = await customerClient.channel('commerce', customerChannelId);

      if (newChannel.state.messages.length) {
        newChannel.state.clearMessages();
      }

      await newChannel.watch();

      setCustomerChannel(newChannel);
    };

    getCustomerChannel();
  }, [customerClient, customerChannelId]);

  return (
    <div>
      {customerChannel && (
        <Channel
          channel={customerChannel}
          EmptyStateIndicator={(props) => (
            null
          )}
          Input={(props) => <CustomerMessageInput {...props} {...{ open, setOpen }} />}
        >
          <Window>
            <MessageList />
            <MessageInput Input={  MessageInputFlat} focus />
          </Window>
          <Thread additionalMessageInputProps={{ focus: false }} />
        </Channel>
      )}
    </div>
  );
};
