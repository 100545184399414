import React from 'react';

import Chat from './screen/Chat';


// const customerClient = StreamChat.getInstance(apiKey);
// customerClient.connectUser(
//     {
//       id: customerUserId,
//       name:`${customerName} ${customerLastName}` ,
//       email: customerEmail,
//     },
//     customerUserToken,
// );

function App() {
  return (
    <Chat></Chat>
  )
}
export default App;
